<template>
	<div>
		<!-- Sign in Card -->
		<b-container fluid>
			<b-row class="d-flex flex-column">
				<!-- Top Half -->
				<b-col cols="12" offset-sm="2" sm="8" offset-lg="3" lg="6">
					<!-- Card -->
					<b-card body-bg-variant="warning" body-text-variant="white" class="curved-bottom-border" body-class="curved-bottom-border" header-bg-variant="primary">
						<!-- Baycho Logo -->
						<template #header>
							<header class="px-5">
								<b-img :src="appLogo" fluid center />
							</header>
						</template>

						<b-row>
							<b-col>
								<!-- As Seller -->
								<b-row class="my-3">
									<b-col>
										<b-button @click="sellerClicked" variant="primary" class="text-white py-3" block pill>
											<small> <b-icon-arrow-right /> AS SELLER </small>
										</b-button>
									</b-col>
								</b-row>

								<!-- As Customer -->
								<b-row class="my-3">
									<b-col>
										<b-button @click="customerClicked" class="py-3" block pill>
											<small> <b-icon-arrow-right /> AS BUYER </small>
										</b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-card>
				</b-col>

				<!-- Bottom Text & Image -->
				<b-col cols="12" offset-sm="2" sm="8" offset-lg="3" lg="6">
					<b-row class="mt-5">
						<b-col cols="12">
							<p class="text-center px-4">
								{{ appDescription }}
							</p>
						</b-col>

						<!-- <b-col cols="12" offset-sm="3" sm="6" offset-lg="4" lg="4"> -->
						<b-col>
							<b-img :src="appHomeImage" class="px-5 pb-3" fluid-grow center />
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

// import CommonNavbar from "@/components/navbars/CommonNavbar.vue";
export default {
	// components: { CommonNavbar },
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			appLogo: "getAppLogo",
			appHomeImage: "getAppHomeImage",
			appDescription: "getAppDescription",
		}),
	},
	methods: {
		sellerClicked() {
			if (this.token) {
				this.$router.push({ name: "MerchantDashboard" });
			} else {
				this.$router.push({
					name: "CheckUserCredentials",
					query: { redirect: "MerchantDashboard" },
				});
			}
		},
		customerClicked() {
			if (this.token) {
				this.$router.push({ name: "BuyerDashboard" });
			} else {
				this.$router.push({
					name: "CheckUserCredentials",
					query: { redirect: "BuyerDashboard" },
				});
			}
		},
	},
};
</script>

<style scoped>
.curved-bottom-border {
	border-bottom-left-radius: 10% !important;
	border-bottom-right-radius: 10% !important;
}
</style>
