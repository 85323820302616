// import {  } from "module";
import genericListStore from "../../functions/genericListStore";
import productImagesStore from "./product-images-store";

export default {
  namespaced: true,
  state: {
    // token: "some token",
    token: "",
  },
  getters: {
    getToken: (state) => state.token,
  },
  mutations: {
    saveToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    saveToken({ commit }, payload) {
      commit("saveToken", payload);
    },
    logout({ commit }) {
      commit("saveToken", null);
    },
  },
  modules: {
    globalCategories: genericListStore,
    globalProducts: genericListStore,
    globalProductImages: productImagesStore,
  },
};
