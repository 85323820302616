import userAccountRoutes from "./user-account-routes";
import userOrdersRoutes from "./user-orders-routes";

export default {
  path: "/postLogin/buyer",
  component: () => import("../../../views/buyer/BuyerParent"),
  children: [
    {
      name: "BuyerDashboard",
      path: "b-dashboard",
      component: () => import("../../../views/buyer/Dashboard"),
    },

    // Account Routes
    userAccountRoutes,

    // User Orders
    userOrdersRoutes,
  ],
};
