import axios from "axios";
import { putItemInList, removeItemFromList } from "../../../functions/utils";

export default {
	namespaced: true,
	state: () => ({
		cartPrescriptions: [],
	}),
	getters: {
		getCartPrescriptions: (state) => state.cartPrescriptions,
	},
	mutations: {
		setCartPrescriptions: (state, cartPrescriptions) => (state.cartPrescriptions = [...cartPrescriptions]),
		addCartPrescription: (state, cartPrescription) => putItemInList(state.cartPrescriptions, cartPrescription, "id"),
		removeCartPrescription: (state, cartPrescriptionId) => removeItemFromList(state.cartPrescriptions, { id: cartPrescriptionId }, "id"),
		reset: (state) => (state.cartPrescriptions = []),
	},
	actions: {
		async getCartPrescriptions({ commit, rootGetters }) {
			const enablePrescription = rootGetters.getEnablePrescription;
			if (!enablePrescription) return Promise.resolve([]);
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			const data = await axios.get("/user/stores/" + storeUrlName + "/cart/prescriptions");
			commit("setCartPrescriptions", data);
			return data;
		},
		async addCartPrescription({ commit, rootGetters }, prescriptionId) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			const data = await axios.post("/user/stores/" + storeUrlName + "/cart/prescriptions", { prescriptionId });
			commit("addCartPrescription", data);
			return data;
		},
		async deleteCartPrescription({ commit, rootGetters }, cartPrescriptionId) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			const data = await axios.delete("/user/stores/" + storeUrlName + "/cart/prescriptions/" + cartPrescriptionId);
			commit("removeCartPrescription", cartPrescriptionId);
			return data;
		},
		reset: ({ commit }) => commit("reset"),
	},
};
