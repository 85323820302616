import axios from "axios";
import { putItemInList, removeItemFromList } from "../../../functions/utils";

const getCarts = () => {
	let carts = JSON.parse(localStorage.getItem(`carts`));
	if (!carts || !Array.isArray(carts)) {
		carts = [];
		saveCarts(carts);
	}
	return carts;
};

const saveCarts = (carts) => {
	localStorage.setItem("carts", JSON.stringify(carts));
};

const removeCartsFromStorage = () => {
	localStorage.removeItem("carts");
};

export default {
	namespaced: true,
	state: () => ({
		// [{storeUrlName: cartId}]
		carts: [...getCarts()],
	}),
	getters: {
		getCartId: (state) => (storeUrlName) => {
			const cart = state.carts.find((cart) => {
				return cart.storeUrlName === storeUrlName;
			});
			return cart ? cart.id : null;
		},
	},
	mutations: {
		addCart(state, { id, storeUrlName }) {
			const carts = putItemInList(state.carts, { id, storeUrlName }, "storeUrlName");
			state.carts = carts;
			saveCarts(carts);
		},
		removeCart(state, { id }) {
			const carts = removeItemFromList(state.carts, { id }, "id");
			state.carts = carts;
			saveCarts(carts);
		},
		removeCarts(state) {
			state.carts = [];
			removeCartsFromStorage();
		},
	},
	actions: {
		async initCart({ commit, dispatch, rootGetters }) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			const data = await dispatch("cartExists");
			let cartId;
			if (!data.exists) {
				// removing cart
				commit("removeCart", { id: cartId });
				cartId = await dispatch("initNewCart", { storeUrlName });
			} else {
				const cart = await dispatch("getCart");
				cartId = cart.id;
			}
			if (process.env.NODE_ENV === "development") {
				console.log("Actions: Cart Id", cartId, storeUrlName);
			}
			return cartId;
		},
		async initNewCart({ commit, dispatch }, { storeUrlName }) {
			const cart = await dispatch("createCartApi");
			const cartId = cart.id;
			if (process.env.NODE_ENV === "development") {
				console.log("Init New Cart || Cart Id", cartId, storeUrlName);
			}
			commit("addCart", { id: cartId, storeUrlName });
			return cartId;
		},
		cartExists({ rootGetters }) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			return axios.get(`/user/stores/${storeUrlName}/cart/exists`);
		},
		createCartApi({ rootGetters }) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			return axios.post(`/user/stores/${storeUrlName}/cart`);
		},
		async getCart({ commit, rootGetters }) {
			const { storeUrlName } = rootGetters["guest/store/getStore"];
			const data = await axios.get(`/user/stores/${storeUrlName}/cart`);
			commit("addCart", { id: data.id, storeUrlName });
			return data;
		},
		removeCarts({ commit }) {
			commit("removeCarts");
		},

		async setCartIfStorePresent({ commit, dispatch, rootGetters }, cart) {
			const store = rootGetters["guest/store/getStore"];
			if (!store) return;
			const { storeUrlName } = store;
			if (!storeUrlName) return;
			const cartId = cart.id;
			commit("addCart", { id: cartId, storeUrlName });
			// Get cart Items and set in store
			await dispatch("user/cartItems/getUserCartItems", null, { root: true });
			// Get user prescriptions and set in store
			await dispatch("user/userCartPrescriptions/getCartPrescriptions", null, { root: true });
		},
	},
};
