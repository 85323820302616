import Vue from "vue";

// Adding Custom components
import FormPassword from "./custom-components/FormPassword";
Vue.component("FormPassword", FormPassword);

// Custom File Upload Plugin
import CustomFileUpload from "./custom-components/CustomFileUpload";
Vue.component("CustomFileUpload", CustomFileUpload);

import Content from "./custom-components/Content";
Vue.component("Content", Content);

// import AutoLogout from "./components/auth/AutoLogout";
// Vue.component("AutoLogout", AutoLogout);

// Adding custom functions to global mixin
import * as utils from "./utils";
import * as errorFunctions from "./errors";
Vue.mixin({
  methods: {
    ...utils,
    ...errorFunctions,
  },
});
