import Vue from "vue";

// NO NEED TO IMPORT BOOTSTRAP CSS (Included inside baycho.scss)
// Bootstrap plugin : https://getbootstrap.com/docs/4.0/
import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// BOOTSTRAP VUE : https://bootstrap-vue.org
// import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// Added baycho scss
import "./themes";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// VUE CLIPBOARD : https://www.npmjs.com/package/vue-clipboard2
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// // FONT AWESOME: https://www.npmjs.com/package/@fortawesome/vue-fontawesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faUserSecret, faRupeeSign } from "@fortawesome/free-solid-svg-icons/";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faUserSecret, faRupeeSign)
// Vue.component('fa-icon', FontAwesomeIcon)
