import { removeStoreImage, updateStore } from "../../apis/merchant";
import { toFormData } from "../../functions/utils";

export default {
  namespaced: true,
  state: () => ({
    store: null,
  }),
  getters: {
    getStore: (state) => state.store,
  },
  mutations: {
    setStore(state, store) {
      state.store = store;
    },
  },
  actions: {
    updateStoreConfig({ commit, getters, rootGetters }, configForm) {
      const token = rootGetters["login/getToken"];
      const {
        storeName,
        storeUrlName,
        storeAddress,
        latitude,
        longitude,
      } = getters.getStore;
      const { mrpHidden, autoWhatsappOrder } = configForm;
      const form = {
        storeName,
        storeUrlName,
        storeAddress,
        latitude,
        longitude,
        mrpHidden,
        autoWhatsappOrder,
      };
      return updateStore(toFormData(form), token).then((data) => {
        commit("setStore", data);
        return data;
      });
    },
    updateStoreAddress({ commit, getters, rootGetters }, addressForm) {
      const token = rootGetters["login/getToken"];
      const {
        storeName,
        storeUrlName,
        mrpHidden,
        autoWhatsappOrder,
      } = getters.getStore;
      const { storeAddress, latitude, longitude } = addressForm;
      const form = {
        storeName,
        storeUrlName,
        storeAddress,
        latitude,
        longitude,
        mrpHidden,
        autoWhatsappOrder,
      };
      return updateStore(toFormData(form), token).then((data) => {
        commit("setStore", data);
        return data;
      });
    },

    updateStoreData({ commit, getters, rootGetters }, storeForm) {
      const token = rootGetters["login/getToken"];
      const { storeAddress, latitude, longitude, mrpHidden } = getters.getStore;
      const { storeName, storeUrlName, autoWhatsappOrder } = storeForm;
      const form = {
        storeName,
        storeUrlName,
        storeAddress,
        latitude,
        longitude,
        mrpHidden,
        autoWhatsappOrder,
      };
      return updateStore(toFormData(form), token).then((data) => {
        commit("setStore", data);
        return data;
      });
    },
    updateStoreImage({ commit, getters, rootGetters }, file) {
      const token = rootGetters["login/getToken"];
      const store = getters.getStore;
      const form = {
        storeName: store.storeName,
        storeUrlName: store.storeUrlName,
        storeAddress: store.storeAddress,
        latitude: store.latitude,
        longitude: store.longitude,
        mrpHidden: store.mrpHidden,
        autoWhatsappOrder: store.autoWhatsappOrder,
      };
      const formData = toFormData(form);
      formData.set("storeImage", file, file.name);
      return updateStore(formData, token).then((data) => {
        commit("setStore", data);
        return data;
      });
    },
    removeStoreImage({ commit, rootGetters }) {
      const token = rootGetters["login/getToken"];
      return removeStoreImage(token).then((data) => {
        commit("setStore", data);
        return data;
      });
    },
    setStore({ commit }, store) {
      commit("setStore", store);
    },
    reset({ commit }) {
      commit("setStore", null);
    },
  },
};
