import Vue from "vue";
import currency from "./currency";

const COUNTRY_CODE = process.env.VUE_APP_COUNTRY_CODE;

const frontEndUrlPrefix = (value) => {
    const customerUrl = window.location.origin; //process.env.VUE_APP_CUSTOMER_URL
    if (!value || !customerUrl) return "";
    value = value.toString();
    return customerUrl.concat(value);
}

Vue.filter('rupeeCurrency', function (value) {
    if (!value) return "";
    value = value.toString();
    return currency.rupee.concat(value);
})

Vue.filter('customerUrl', frontEndUrlPrefix)
Vue.filter('merchantUrl', frontEndUrlPrefix)

Vue.filter('orderStatusToDisplay', function (value) {
    if (!value) return "";
    if (value == "user_cancelled") {
        return "User Cancelled"
    }
    if (value == "merchant_declined") {
        return "Merchant Declined"
    }
    if (value == "merchant_cancelled") {
        return "Merchant Cancelled"
    }
    return value.toString();
})

Vue.filter('attachCountryCode', function (value) {
    if (!value) return "";
    value = value.toString();
    if (!COUNTRY_CODE)
        return value;
    return COUNTRY_CODE.concat(value);
})
