import { getProductImages as getGuestProductImages } from "../../apis/guest";
import { getProductImages as getMerchantProductImages } from "../../apis/merchant";
import { putItemInList } from "../../functions/utils";

const getImages = (state, productId) => {
  // console.log("GET CALLED", productId);
  const images = state.productImages.find((productImage) => {
    return productImage.id === productId;
  });
  if (images) {
    return images.images;
  }
  return [];
};

const setImages = (state, productId, images) => {
  // console.log("SET CALLED", productId);
  let productImages = state.productImages;
  putItemInList(productImages, { id: productId, images }, "id");
  state.productImages = productImages;
};

export default {
  namespaced: true,
  // state: {
  //     productImages: [],
  // },
  state: () => ({
    // A List Of Map
    /*
      productImages: [
        {id: productId , images : [{id, src}, {id, src}]}
      ]
    */
    productImages: [],
  }),
  getters: {
    getProductImages: (state) => (productId) => {
      return getImages(state, productId);
    },
  },
  mutations: {
    // SEND DATA AS : {id: productId , images : [{id, src}, {id, src}
    setProductImages(state, data) {
      let id = data.id;
      let images = data.images;
      setImages(state, id, images);
    },
    // SEND DATA AS : {id: productId, image : {id, src}}
    addProductImage(state, data) {
      const id = data.id;
      const image = data.image;
      let images = getImages(state, id);
      images.push(image);
      setImages(state, id, images);
    },

    // SEND DATA AS : {id: productId, imageId}
    removeProductImage(state, data) {
      const id = data.id;
      const imageId = data.imageId;
      let images = getImages(state, id);
      if (images) {
        const index = images.findIndex((image) => {
          return image.id === imageId;
        });
        if (index !== -1) {
          images.splice(index, 1);
          setImages(state, id, images);
        }
      }
    },
    reset(state) {
      const length = state.productImages.length;
      state.productImages.splice(0, length);
    },
  },
  actions: {
    getGuestProductImages({ commit }, { storeUrlName, productId }) {
      return getGuestProductImages(storeUrlName, productId).then((data) => {
        const images = data.map((image) => {
          const src = image.productImage.blobUrl;
          return { id: image.id, src };
        });
        commit("setProductImages", { id: productId, images });
        return images;
      });
    },
    getMerchantProductImages({ commit }, { productId, token }) {
      return getMerchantProductImages(productId, token).then((data) => {
        // array of productImages setting to state
        const images = data.map((image) => {
          const src = image.productImage.blobUrl;
          return { id: image.id, src };
        });
        commit("setProductImages", { id: productId, images });
        return images;
      });
    },
    setProductImages({ commit }, payload) {
      commit("setProductImages", payload);
    },
    addProductImage({ commit }, payload) {
      commit("addProductImage", payload);
    },
    removeProductImage({ commit }, payload) {
      commit("removeProductImage", payload);
    },
    reset({ commit }) {
      commit("reset");
    },
  },
};
