import router from "./router";
import store from "./store";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
axios.defaults.headers.get["Accepts"] = "application/json";

axios.interceptors.request.use(function(config) {
  const token = store.getters["login/getToken"];
  // config.headers is sometimes null for pre-flight requests
  if (token && config.headers)
    config.headers.common["Authorization"] = "Bearer " + token;
  return config;
});

axios.interceptors.response.use(
  function(response) {
    // do nothing but return since reponse is success
    return response.data;
  },
  function(underlyingError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const response = underlyingError.response;
    if (!response) return Promise.reject(underlyingError);

    // creating error wrapper of underlying error
    const err = new ErrorWrapper(underlyingError);

    const data = err.data;
    if (!data) return Promise.reject(err);

    const errors = data.errors;
    if (!errors) return Promise.reject(err);

    const token = errors.token;
    if (!token) return Promise.reject(err);

    // token error
    // remove token from state.
    // PERFORM LOGOUT
    store.dispatch("login/logout", null, { root: true }).then(() => {
      // redirect to home page
      router.replace("/");
    });

    return Promise.reject(err);
  }
);

class ErrorWrapper extends Error {
  constructor(error) {
    super(error.message);
    this.error = error;

    // custom data
    var data = {};
    if (error.response) {
      const errorData = error.response.data;
      if (errorData && !errorData.status) {
        data = {
          errors: errorData.errors,
          data: errorData.data,
          status: error.response.status,
        };
      }
    }
    this.data = data;
  }
}
