const routes = [
    // DeliverySettings
    {
        path: "deliverySettings",
        name: "DeliverySettings",
        component: () => import('../../views/merchant/store/deliverySettings/DeliverySettingsPage'),
    },

    // DeliveryAreaForm
    {
        path: "deliveryAreaForm",
        name: "DeliveryAreaForm",
        component: () => import('../../views/merchant/store/deliverySettings/DeliveryAreaForm'),
    },

    // DeliveryFeeForm
    {
        path: "deliveryFeeForm",
        name: "DeliveryFeeForm",
        component: () => import('../../views/merchant/store/deliverySettings/DeliveryFeeForm'),
    },

];

export default routes;