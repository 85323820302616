import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

export const MAP_API_KEY = process.env.VUE_APP_MAP_API_KEY;

let key = MAP_API_KEY ? MAP_API_KEY : "";

// Vue2-Google-Maps : https://www.npmjs.com/package/vue2-google-maps, http://xkjyeah.github.io/vue-google-maps/autoapi.html
Vue.use(VueGoogleMaps, {
  load: {
    key,
    libraries: "places, drawing", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  // installComponents: true
});
