<template>
  <b-form-group
    :invalid-feedback="invalidFeedback"
    :state="state"
    :label="label"
    :label-for="labelFor"
  >
    <b-input-group :id="labelFor">
      <b-form-input
        :state="state"
        :id="id"
        :value="content"
        @input="handleInput"
        :type="visibility"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :required="required"
      ></b-form-input>
      <b-input-group-append>
        <b-input-group-text :key="visible" @click="toggle">
          <!-- IF VISIBLE SHOW SLASHED ICON -->
          <b-icon-eye-slash v-if="visible"></b-icon-eye-slash>

          <!-- IF NOT VISIBLE SHOW FULL ICON -->
          <b-icon-eye v-if="!visible"></b-icon-eye>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    labelFor: { type: String, default: "" },
    id: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    maxlength: { type: Number, default: 50 },
    required: { type: Boolean, default: false },
    invalidFeedback: {
      type: String,
      default: "",
    },
    state: {},
    // onlyunder: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: false,
      content: this.value,
    };
  },
  computed: {
    visibility() {
      return this.visible ? "text" : "password";
    },
    iconClass() {
      return this.visible ? "eye-slash" : "eye";
    },
  },
  methods: {
    invalidFeedbackHandler() {
      return this.invalidFeedback;
    },
    handleInput(value) {
      this.content = value;
      this.$emit("input", value);
    },
    toggle() {
      this.visible = !this.visible;
    },
  },
};
</script>
