export const getErrorState = (jsonObj, key) => {
  // console.log("Called 1", jsonObj, key);
  return getErrorStateAndMessage(jsonObj, key).state;
};

export const getErrorMessage = (jsonObj, key) => {
  // console.log("Called 2", jsonObj, key);
  return getErrorStateAndMessage(jsonObj, key).message;
};

// Function returns a json object of { state: null|false|true, 'message': null|empty|value })
export const getErrorStateAndMessage = (jsonObj, key) => {
  const obj = jsonObj;

  // No object present
  if (!obj) {
    // console.log("Null", obj, key);
    return { state: null, message: null };
  }

  const hasOwnProperty = Object.prototype.hasOwnProperty.call(obj, key);
  if (!hasOwnProperty) {
    // console.log("Null 1", obj, key);
    return { state: null, message: null };
  }

  const value = obj[key];
  // No Errors
  if (!value) {
    // console.log("True", value, key);
    return { state: true, message: null };
  }

  // console.log("FALSE", value, key);
  return { state: false, message: value };
};
