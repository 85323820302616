const routes = [
  // {
  //   path: "login",
  //   name: "AdminLogin",
  //   component: () => import('@/views/auth/Login.vue')
  // },
  {
    path: "global",
    component: () => import("@/views/admin/global/AdminGlobalParent"),
    children: [
      {
        path: "categories",
        name: "GlobalCategories",
        component: () =>
          import("@/views/admin/global/categories/GlobalCategoriesList"),
      },
      {
        path: "products",
        name: "GlobalProducts",
        component: () =>
          import("@/views/admin/global/products/GlobalProductsList"),
      },
      {
        path: "product-form",
        name: "GlobalProductForm",
        component: () =>
          import("@/views/admin/global/products/GlobalProductForm"),
      },
    ],
  },
];

export default routes;
