import httpClient from "../functions/httpClient";

// -------------------------------------- MASTERS --------------------------------------
export const getUnits = () => {
    return httpClient.GET("/masters/units")
}
export const getOrderStatuses = () => {
    return httpClient.GET("/masters/orderStatuses")
}
// -------------------------------------- END MASTERS --------------------------------------

// -------------------------------------- AUTH --------------------------------------
export const checkUserCredentials = (mobile) => {
    return httpClient.GET("/user-check", null, { mobile })
}

export const authenticate = (loginData) => {
    return httpClient.POST("/authenticate", null, null, loginData);
}

export const register = (registerForm) => {
    return httpClient.POST("/register", null, null, registerForm)
}

export const userForgotPassword = (mobile) => {
    return httpClient.POST("/forgot/password", null, { mobile })
}

export const resetForgotPassword = (form) => {
    return httpClient.POST("/forgot/resetPassword", null, null, form)
}
// -------------------------------------- END AUTH --------------------------------------

// -------------------------------------- VERIFY OTP --------------------------------------
export const verifyOtp = (otpData) => {
    return httpClient.POST("/verify/otp", null, null, otpData);
}
// -------------------------------------- END VERIFY OTP --------------------------------------

export const getHeaders = (token) => {
    const headers = {};
    headers.Authorization = "Bearer " + token;
    return headers;
}

// function getHeaders(token) {
//     const headers = {};
//     headers.Authorization = "Bearer " + token;
//     return headers;
// }