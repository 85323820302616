// const merchantLoginToken = {
//   id: 38,
//   userId: 1,
//   role: "MERCHANT",
//   token: "fxCX6NhIoOjsoYSJOudFxFUErvBGTC",
//   deviceId: null,
//   loginType: null,
//   lastLoggedInTime: 1599091858882,
//   logoutTime: null,
//   loggedIn: true,
// };

// const adminLoginToken = {
//   id: 5,
//   userId: 1,
//   role: "ADMIN",
//   token: "IC7diERB3auhT2VhqOGDnGBociaVmR",
//   deviceId: null,
//   loginType: null,
//   lastLoggedInTime: 1599091858882,
//   logoutTime: null,
//   loggedIn: true,
// };

export const variables = {
  mobile: "",
  password: "",
  loginToken: null,
};
