<template>
  <!-- USAGE TIP -->

  <!--
    <CustomFileUpload
      :mainClassName="'justify-content-center'"
      :uploadBtnClassName="'mb-2'"
      :acceptFileType="image/*"
      :enableDelete="enableDelete"
      :enableUpload="enableUpload"
      :removeFileOnUpload="false"
      @fileSelected="fileSelected"
      @reset="reset"
      @upload="onUpload"
      @delete="onDelete"
    />
    -->

  <div class="file-upload-wrapper">
    <div class="d-flex" :class="this.mainClassName">
      <div class="p-2">
        <b-form-file
          v-model="fileSelected"
          :accept="acceptFileType"
        ></b-form-file>
      </div>
      <div class="p-2" v-if="fileSelected">
        <b-button @click="onReset">Reset</b-button>
      </div>
      <div class="p-2" v-if="enableDelete">
        <b-button variant="danger" @click="onDelete"> Delete </b-button>
      </div>
    </div>

    <div
      v-if="this.enableUpload && this.image"
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        class="align-self-center"
        :class="uploadBtnClassName"
        @click="onConfirmUpload"
      >
        Upload
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    acceptFileType: {
      type: String,
    },
    mainClassName: {
      type: String,
    },
    uploadBtnClassName: {
      type: String,
    },
    enableUpload: {
      type: Boolean,
      required: true,
    },
    removeFileOnUpload: {
      type: Boolean,
    },
    enableDelete: {
      type: Boolean,
    },
  },
  data() {
    return {
      isNew: true,
      image: null,
    };
  },
  computed: {
    fileSelected: {
      get() {
        return this.image;
      },
      set(newValue) {
        this.image = newValue;
        if (newValue) this.$emit("fileSelected", newValue);
        else this.$emit("reset");
      },
    },
  },
  methods: {
    onReset() {
      // will internalled call fileSelected
      this.image = null;
    },
    onDelete() {
      if (this.enableDelete) this.$emit("delete");
    },
    onConfirmUpload() {
      if (this.image) {
        this.$emit("upload", this.image);
        if (this.removeFileOnUpload) {
          this.image = null;
        }
      }
    },
  },
};
</script>
