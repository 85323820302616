const routes = [
    // BusinessHoursPage
    {
        path: "businessHoursPg",
        name: "BusinessHoursPage",
        component: () => import('../../views/merchant/store/businessHours/BusinessHoursPage'),
    },
    // BusinessHourForm
    {
        path: "businessHourForm/:day",
        name: "BusinessHourForm",
        component: () => import('../../views/merchant/store/businessHours/BusinessHourForm'),
    }
];

export default routes;