import merchantAccountRoutes from "./merchant-account-routes";
export default {
  path: "/postLogin/merchant",
  component: () => import("../../views/merchant/MerchantPostLoginParent"),
  children: [
    // Dashboard
    {
      path: "m-dashboard",
      name: "MerchantDashboard",
      redirect: "orders/list",
    },

    // EXTRAS
    // STORE (REGISTER)
    {
      path: "store-register",
      name: "RegisterStoreForm",
      component: () => import("../../views/merchant/store/RegisterStoreForm"),
    },

    // Orders
    {
      path: "orders",
      name: "Orders",
      component: () => import("../../views/merchant/orders/OrdersParent"),
      redirect: "orders/list",
      children: [
        {
          path: "list",
          component: () => import("../../views/merchant/orders/OrdersList"),
        },
        {
          path: "detail",
          name: "OrderDetail",
          component: () => import("../../views/merchant/orders/OrderDetail"),
        },
      ],
    },

    // Products
    {
      path: "products",
      name: "Products",
      component: () => import("../../views/merchant/products/ProductsParent"),
      redirect: "products/list",
      children: [
        {
          path: "list",
          name: "ProductsList",
          component: () => import("../../views/merchant/products/ProductsList"),
        },
        {
          path: "form",
          name: "ProductForm",
          component: () => import("../../views/merchant/products/ProductForm"),
        },
        {
          path: "global-list",
          name: "GlobalProductsList",
          component: () =>
            import("../../views/merchant/products/GlobalProductsList"),
        },
      ],
    },

    // Categories
    {
      path: "categories",
      name: "Categories",
      component: () =>
        import("../../views/merchant/categories/CategoriesParent"),
      redirect: "categories/list",
      children: [
        {
          path: "list",
          name: "CategoriesList",
          component: () =>
            import("../../views/merchant/categories/CategoriesList"),
        },
        {
          path: "form",
          name: "CategoryForm",
          component: () =>
            import("../../views/merchant/categories/CategoryForm"),
        },
      ],
    },

    // Merchant Account
    ...merchantAccountRoutes,
  ],
};
