export default {
	path: "cart",
	name: "Cart",
	component: () => import("../../views/store/cart/CartParent"),
	redirect: "cart/page",
	children: [
		{
			path: "page",
			component: () => import("../../views/store/cart/CartPage"),
		},
		{
			path: "order",
			name: "CartPlaceOrder",
			component: () => import("../../views/store/cart/checkout/CartPlaceOrder"),
		},
		{
			path: "success",
			name: "CartSuccessPlaceOrder",
			component: () => import("../../views/store/cart/CartSuccessPlaceOrder"),
		},

		// Checkout login
		{
			path: "checkout-auth",
			name: "CartLogin",
			component: () => import("../../views/store/cart/checkout/login/CartAuthParent"),
			redirect: "checkout-auth/check-cred",
			children: [
				{
					path: "check-cred",
					name: "CartCheckUserCredentials",
					component: () => import("../../views/store/cart/checkout/login/CartCheckUserCredentials"),
				},
				{
					path: "login",
					name: "CartAuthenticate",
					component: () => import("../../views/store/cart/checkout/login/CartAuthenticate"),
				},
				{
					path: "register",
					name: "CartRegister",
					component: () => import("../../views/store/cart/checkout/login/CartRegister"),
				},
				{
					path: "reset-password",
					name: "CartResetPassword",
					component: () => import("../../views/store/cart/checkout/login/CartResetPassword"),
				},
				{
					path: "verifyOtp",
					name: "CartVerifyOtp",
					component: () => import("../../views/store/cart/checkout/login/CartVerifyOtp"),
				},
			],
		},
	],
};
