export default {
  path: "auth",
  // name:
  component: () => import("../../views/auth/AuthParent"),
  children: [
    {
      path: "check-cred",
      name: "CheckUserCredentials",
      component: () => import("../../views/auth/CheckUserCredentials"),
    },
    {
      path: "login",
      name: "Authenticate",
      component: () => import("../../views/auth/Authenticate.vue"),
    },
    {
      path: "register",
      name: "Register",
      component: () => import("../../views/auth/Register.vue"),
    },
    {
      path: "auth-verify-otp",
      name: "AuthVerifyOtp",
      component: () => import("../../views/auth/AuthVerifyOtp.vue"),
    },
    {
      path: "reset-password",
      name: "ResetPassword",
      component: () => import("../../views/auth/ResetPassword.vue"),
    },
  ],
};
