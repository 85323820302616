import liberateMedsLogo from "@/assets/imgs/liberate-meds/liberate-meds-logo.jpg";
import baychoLogo from "@/assets/imgs/baycho/baycho-long-logo.png";

import liberateMedsShortLogo from "@/assets/imgs/liberate-meds/liberate-meds-short-logo.png";
import baychoShortLogo from "@/assets/imgs/baycho/baycho-short-logo.png";

import liberateMedsFavIcon from "@/assets/imgs/liberate-meds/liberate-meds-favicon.png";
import baychoFavIcon from "@/assets/imgs/baycho/baycho-favicon.png";

import liberateMedsHomeImage from "@/assets/imgs/liberate-meds/liberate-meds-short-logo.png";
import baychoHomeImage from "@/assets/imgs/baycho/groceries.jpg";

const liberateMedsDescription = "Liberate Meds allows small pharmacies to conduct retail in an online environment";
const baychoDescription = "Baycho allows small businesses to conduct retail in an online environment";

export default {
	state: {
		enablePrescription: process.env.VUE_APP_ENABLE_PRESCRIPTION === "true",
	},
	getters: {
		getEnablePrescription: (state) => state.enablePrescription,
		getAppLogo: (state) => (state.enablePrescription ? liberateMedsLogo : baychoLogo),
		getAppShortLogo: (state) => (state.enablePrescription ? liberateMedsShortLogo : baychoShortLogo),
		getAppDescription: (state) => (state.enablePrescription ? liberateMedsDescription : baychoDescription),
		getAppTitle: (state) => (state.enablePrescription ? "Liberate Meds" : "Baycho"),
		getFavIcon: (state) => (state.enablePrescription ? liberateMedsFavIcon : baychoFavIcon),
		getAppHomeImage: (state) => (state.enablePrescription ? liberateMedsHomeImage : baychoHomeImage),
	},
	mutations: {},
	actions: {},
};
