const prescriptionRoutes = [
	{
		path: "prescriptions",
		name: "UserPrescriptions",
		component: () => import("@/views/buyer/account/prescriptions/UserPrescriptions"),
	},
	{
		path: "prescription-form",
		name: "UserPrescriptionForm",
		component: () => import("@/views/buyer/account/prescriptions/UserPrescriptionFormView"),
	},
];

export default process.env.VUE_APP_ENABLE_PRESCRIPTION === "true" ? prescriptionRoutes : [];
