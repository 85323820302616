import {
  getUnits as getUnitsApi,
  getOrderStatuses as getOrderStatusesApi,
} from "@/apis";

export default {
  namespaced: true,
  state: {
    units: [],
    orderStatuses: [],
  },
  getters: {
    getUnits: (state) => state.units,
    getOrderStatuses: (state) => state.orderStatuses,
  },
  mutations: {
    setUnits(state, units) {
      state.units = units;
    },
    setOrderStatuses(state, orderStatuses) {
      state.orderStatuses = orderStatuses;
    },
    reset(state) {
      // Units
      state.units.splice(0, state.units.length);

      // Statuses
      state.orderStatuses.splice(0, state.orderStatuses.length);
    },
  },
  actions: {
    loadMasters({ dispatch }) {
      dispatch("setUnits");
      dispatch("setOrderStatuses");
    },
    setUnits({ commit }) {
      getUnitsApi().then((data) => {
        commit("setUnits", data);
      });
    },
    setOrderStatuses({ commit }) {
      getOrderStatusesApi().then((data) => {
        commit("setOrderStatuses", data);
      });
    },
    reset({ commit }) {
      commit("reset");
    },
  },
};
