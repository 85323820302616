<template>
	<router-view />
</template>

<script>
import { mapGetters } from "vuex";
export default {
	created() {
		this.setTitle(this.appTitle);
		this.setFavIcon(this.favIcon);
	},
	computed: {
		...mapGetters({
			appTitle: "getAppTitle",
			favIcon: "getFavIcon",
		}),
	},
	watch: {
		appTitle(value) {
			this.setTitle(value);
		},
		favIcon(value) {
			this.setFavIcon(value);
		},
	},
	methods: {
		setTitle(title) {
			document.title = title;
		},
		setFavIcon(icon) {
			const favIcon = document.getElementById("favicon");
			favIcon.href = icon;
		},
	},
};
</script>

<style>
@import url("https://use.typekit.net/hgt2mfm.css");

body {
	font-family: "aktiv-grotesk" !important;
}
</style>
