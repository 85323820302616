import Vue from "vue";
import App from "./App.vue";

// store setup
import store from "./store";

// router setup
import router from "./router";

// Axios Plugin
import "./axios-dependency";

// BootstrapVue plugin
import "./bootstrap-vue";

// Google Map
import "./google-map-vue";

// Vue Image Upload Resize
import "./image-upload-resize";

// Custom functions import
import "./functions";

// Adding Filters
import "./filter";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (createElement) => createElement(App),
}).$mount("#app");
