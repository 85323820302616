import httpClient from "../functions/httpClient";
import { getHeaders } from "./index";

// -------------------------------------- STORE --------------------------------------
export const getStore = (token) => {
	return httpClient.GET("/merchant/store", getHeaders(token));
};
export const updateStore = (storeForm, token) => {
	return httpClient.PUT("/merchant/store", getHeaders(token), null, storeForm);
};
export const removeStoreImage = (token) => {
	return httpClient.DELETE("/merchant/store/image", getHeaders(token));
};
// -------------------------------------- DELIVERY SETTINGS --------------------------------------
export const getDeliverySettings = (token) => {
	return httpClient.GET("/merchant/store/delivery-settings", getHeaders(token));
};
export const updateDeliverySettings = (feeRequestForm, areaRequestForm, token) => {
	let settingsForm = {};
	if (feeRequestForm) {
		settingsForm.feeRequest = feeRequestForm;
	}
	if (areaRequestForm) {
		settingsForm.areaRequest = areaRequestForm;
	}
	return httpClient.PUT("/merchant/store/delivery-settings", getHeaders(token), null, settingsForm);
};
// -------------------------------------- END DELIVERY SETTINGS --------------------------------------
// -------------------------------------- BUSINESS HOURS --------------------------------------
export const getBusinessHours = (token) => {
	return httpClient.GET("/merchant/store/business-hours", getHeaders(token));
};
export const updateBusinessDay = (day, form, token) => {
	return httpClient.PUT("/merchant/store/business-hours/" + day, getHeaders(token), null, form);
};
// -------------------------------------- END BUSINESS HOURS --------------------------------------
// -------------------------------------- END STORE --------------------------------------

// -------------------------------------- CATEGORIES --------------------------------------
export const getCategories = (token) => {
	return httpClient.GET("/merchant/categories", getHeaders(token));
};
export const addCategory = (categoryForm, token) => {
	return httpClient.POST("/merchant/categories", getHeaders(token), null, categoryForm);
};
export const updateCategory = (categoryId, categoryForm, token) => {
	return httpClient.PUT("/merchant/categories/" + categoryId, getHeaders(token), null, categoryForm);
};
export const deleteCategory = (categoryId, token) => {
	return httpClient.DELETE("/merchant/categories/" + categoryId, getHeaders(token));
};
// -------------------------------------- END CATEGORIES --------------------------------------

// -------------------------------------- PRODUCTS --------------------------------------
export const getProductsCount = (token, params) => {
	return httpClient.GET("/merchant/products/count", getHeaders(token), params);
};
export const getProducts = (token, params) => {
	return httpClient.GET("/merchant/products", getHeaders(token), params);
};
export const addProduct = (productForm, token) => {
	return httpClient.POST("/merchant/products", getHeaders(token), null, productForm);
};
export const updateProduct = (productId, productForm, token) => {
	return httpClient.PUT("/merchant/products/" + productId, getHeaders(token), null, productForm);
};
export const deleteProduct = (productId, token) => {
	return httpClient.DELETE("/merchant/products/" + productId, getHeaders(token));
};
// -------------------------------------- PRODUCT IMAGES --------------------------------------
export const getProductImages = (productId, token) => {
	return httpClient.GET("/merchant/products/" + productId + "/images", getHeaders(token));
};
export const addProductImage = (productId, productImageData, token) => {
	return httpClient.POST("/merchant/products/" + productId + "/images", getHeaders(token), null, productImageData);
};
export const deleteProductImage = (productId, productImageId, token) => {
	return httpClient.DELETE("/merchant/products/" + productId + "/images/" + productImageId, getHeaders(token));
};
// -------------------------------------- END PRODUCT IMAGES --------------------------------------
// -------------------------------------- END PRODUCTS --------------------------------------

// -------------------------------------- ORDERS --------------------------------------
export const getOrdersCount = (token, params) => {
	return httpClient.GET("/merchant/orders/count", getHeaders(token), params);
};

export const getOrders = (token, params) => {
	return httpClient.GET("/merchant/orders", getHeaders(token), params);
};

export const getOrder = (orderId, token, params) => {
	return httpClient.GET("/merchant/orders/" + orderId, getHeaders(token), params);
};

export const updateOrderStatus = (orderId, orderStatus, token) => {
	return httpClient.PUT("/merchant/orders/" + orderId, getHeaders(token), null, { orderStatus });
};
// -------------------------------------- ORDER ITEMS --------------------------------------
export const getOrderItems = (orderId, token) => {
	return httpClient.GET("/merchant/orders/" + orderId + "/items", getHeaders(token));
};
export const updateOrderItem = (orderId, orderItemId, form, token) => {
	return httpClient.PUT("/merchant/orders/" + orderId + "/items/" + orderItemId, getHeaders(token), null, form);
};
export const addOrderItem = (orderId, productId, form, token) => {
	return httpClient.POST("/merchant/orders/" + orderId + "/items/" + productId, getHeaders(token), null, form);
};
// -------------------------------------- END ORDER ITEMS --------------------------------------
// -------------------------------------- END ORDERS --------------------------------------

// -------------------------------------- GLOBAL CATEGORIES --------------------------------------
export const getGlobalCategories = (token) => {
	return httpClient.GET("/merchant/global/categories", getHeaders(token));
};
export const getGlobalCategoryById = (categoryId, token) => {
	return httpClient.GET("/merchant/global/categories/" + categoryId, getHeaders(token));
};
export const getGlobalProductsCountForGlobalCategory = (categoryId, token, params) => {
	return httpClient.GET("/merchant/global/categories/" + categoryId + "/products/count", getHeaders(token), params);
};
export const getGlobalProductsForGlobalCategory = (categoryId, token, params) => {
	return httpClient.GET("/merchant/global/categories/" + categoryId + "/products", getHeaders(token), params);
};
// -------------------------------------- END GLOBAL CATEGORIES --------------------------------------

// -------------------------------------- GLOBAL PRODUCTS --------------------------------------
export const getGlobalProductsCount = (token, params) => {
	return httpClient.GET("/merchant/global/products/count", getHeaders(token), params);
};
export const getGlobalProducts = (token, params) => {
	return httpClient.GET("/merchant/global/products", getHeaders(token), params);
};
// -------------------------------------- GLOBAL PRODUCT IMAGES --------------------------------------
export const getGlobalProductImages = (productId, token) => {
	return httpClient.GET("/merchant/global/products/" + productId + "/images", getHeaders(token));
};
// -------------------------------------- END GLOBAL PRODUCT IMAGES --------------------------------------
// -------------------------------------- END GLOBAL PRODUCTS --------------------------------------
