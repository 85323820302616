import deliverySettingsRoutes from "./delivery-settings-routes";
import businessHoursRoutes from "./business-hours-routes";

const routes = [
  // Account
  {
    path: "merchant-account",
    name: "MerchantAccount",
    component: () => import("../../views/merchant/account/Account"),
    redirect: "merchant-account/details",
    children: [
      {
        path: "details",
        component: () => import("../../views/merchant/account/AccountDetails"),
      },
      {
        path: "form",
        name: "StoreForm",
        component: () => import("../../views/merchant/store/StoreForm"),
      },
      {
        path: "address",
        name: "StoreAddressView",
        component: () => import("../../views/merchant/store/StoreAddressView"),
      },

      // Store Configuration
      {
        path: "config",
        name: "MerchantStoreConfig",
        component: () =>
          import("../../views/merchant/account/MerchantStoreConfigPage"),
      },

      // Delivery setting routes
      ...deliverySettingsRoutes,

      // Business hours routes
      ...businessHoursRoutes,
    ],
  },
];

export default routes;
