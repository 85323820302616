import axios from "axios";
import { putItemInList, removeItemFromList } from "../../../functions/utils";

const getGuestCarts = () => {
	let carts = JSON.parse(localStorage.getItem(`guest-carts-new`));
	if (!carts) {
		carts = {};
		saveGuestCarts(carts);
	}
	return carts;
};

const getCartItems = (state) => {
	// let cartItems = JSON.parse(localStorage.getItem(`guest-cart-${storeId}`));
	const key = state.currentStoreUrlName;
	if (!key) {
		return [];
	}
	let cartItems = state.carts[key];
	if (!cartItems || !Array.isArray(cartItems)) {
		cartItems = [];
		setStoreCartItems(state, cartItems);
	}
	return cartItems;
};

const getProductCartItem = (state, productId) => {
	const cartItems = getCartItems(state);
	if (!cartItems || cartItems.length == 0) {
		return null;
	}
	return [...cartItems].find((cartItem) => cartItem.product && cartItem.product.id === productId);
};

const setStoreCartItems = (state, cartItems) => {
	const key = state.currentStoreUrlName;
	if (!key) return;
	// NOTE: Replacing full cart's object for reactive-ness
	// get all carts
	const carts = state.carts;
	// replace object
	carts[key] = cartItems;
	// save new carts object to state
	state.carts = { ...carts };
	saveGuestCarts(carts);
};

const removeGuestCart = (state, key) => {
	const carts = state.carts;
	delete carts[key];
	state.carts = { ...carts };
	saveGuestCarts(carts);

	if (Object.keys(carts).length == 0) {
		removeGuestCartsFromStorage();
	}
};

const saveGuestCarts = (carts) => {
	localStorage.setItem(`guest-carts-new`, JSON.stringify(carts));
};

const removeGuestCartsFromStorage = () => {
	localStorage.removeItem(`guest-carts-new`);
};

// Cart Item:
const createCartItem = (product, quantity) => {
	const productId = product.id;
	const id = `guest_cart_item_${productId}`;
	return {
		id,
		product,
		productId,
		quantity,
	};
};

export default {
	namespaced: true,
	state: () => ({
		carts: { ...getGuestCarts() },
		currentStoreUrlName: null,
	}),
	getters: {
		getCarts: (state) => state.carts,
		getCurrentStoreUrlName: (state) => state.currentStoreUrlName,
		getCartItems: (state) => {
			return getCartItems(state);
		},
	},
	mutations: {
		setCurrentStore(state, store) {
			const { storeUrlName } = store;
			state.currentStoreUrlName = storeUrlName;
		},
		createStoreCart(state) {
			getCartItems(state);
		},
		addProductToCart(state, { product, quantity }) {
			const items = putItemInList(getCartItems(state), createCartItem(product, quantity), "id");
			setStoreCartItems(state, items);
		},
		deleteProductFromCart(state, { productId }) {
			const item = getProductCartItem(state, productId);
			if (item) {
				const items = removeItemFromList(getCartItems(state), item, "id");
				setStoreCartItems(state, items);
			}
		},
		removeGuestCart(state, key) {
			removeGuestCart(state, key);
		},
		removeGuestCarts(state) {
			state.carts = {};
			removeGuestCartsFromStorage();
		},
	},
	actions: {
		// new store cart
		setCurrentStore({ commit }, store) {
			commit("setCurrentStore", store);
		},
		createStoreCart({ commit }) {
			commit("createStoreCart");
		},
		addProductToCart({ commit }, payload) {
			commit("addProductToCart", payload);
		},
		updateCartItem({ commit }, payload) {
			commit("addProductToCart", payload);
		},
		deleteProductFromCart({ commit }, payload) {
			commit("deleteProductFromCart", payload);
		},
		removeGuestCart({ commit }, key) {
			commit("removeGuestCart", key);
		},
		removeGuestCarts({ commit }) {
			commit("removeGuestCarts");
		},

		// adding guest cart items to user cart
		addGuestCartItemsToUserCart({ dispatch, getters }) {
			// get all guest carts, i.e. store + cart combo.
			const carts = getters.getCarts;
			if (!carts) return;

			const storeUrlName = getters.getCurrentStoreUrlName;
			if (storeUrlName) {
				// marking as store visited
				dispatch("user/storeVisited", null, { root: true });
			}

			// for each guest-cart, fetch cart items
			const keys = Object.keys(carts);
			keys.forEach(async (key) => {
				const cartItems = carts[key];
				if (!cartItems || cartItems.length <= 0) {
					// removing cart with no items for that key
					await dispatch("removeGuestCart", key);
					return;
				}
				// make api call to sync
				const dataToPost = [...cartItems].map((item) => {
					return { productId: item.product.id, quantity: item.quantity };
				});
				const cart = await axios.post(`/user/stores/${key}/cart/guestSync`, dataToPost);
				await dispatch("user/cart/setCartIfStorePresent", cart, { root: true });
				await dispatch("removeGuestCart", key);
			});
		},
	},
};
