import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from "../views/HomePage.vue";
import { addGlobalRouterGuard } from "./router-guard";

// NOT FOUND
import NoStoreFound from "../views/NoStoreFound";
import NotFound from "../views/NotFoundPage";

// auth routes
import authRoutes from "./routes/auth-routes";

// merchant routes
import merchantRoutes from "./routes/merchant-routes";
// buyer routes
import buyerRoutes from "./routes/buyer-routes/index";

// admin routes
import adminRoutes from "./routes/admin-routes";

// store routes
import storeRoutes from "./routes/store-routes";

const routes = [
  { path: "/", name: "Home", component: HomePage },

  // Prelogin Auth
  {
    path: "/preLogin",
    name: "PreLoginParent",
    component: () => import("../views/PreLoginParent"),
    redirect: "/",
    children: [authRoutes],
  },

  // Post Login Routes (merchant & buyers)
  merchantRoutes,
  buyerRoutes,

  // Admin Routes
  {
    path: "/admin",
    name: "AdminParent",
    component: () => import("../views/admin/AdminParent"),
    children: [...adminRoutes],
  },

  // Store Routes
  {
    path: "/:storeUrlName",
    name: "StoreHome",
    component: () => import("../views/store/MainStore"),
    redirect: "/:storeUrlName/s-dashboard",
    children: [...storeRoutes],
  },

  // No Store Found page
  { path: "/no-store", name: "NoStoreFound", component: NoStoreFound },

  // Not Found page
  { path: "*", name: "NotFound", component: NotFound },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active nav-item-active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

addGlobalRouterGuard(router);

export default router;
