import { getDeliverySettings } from "../../apis/merchant";
export default {
  namespaced: true,
  state: () => ({
    settings: null,
  }),
  getters: {
    getSettings: (state) => {
      return state.settings;
    },
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    async initSettings({ commit }, token) {
      const data = await getDeliverySettings(token);
      commit("setSettings", data);
      return data;
    },
    setSettings({ commit }, settings) {
      commit("setSettings", settings);
    },
    reset({ commit }) {
      commit("setSettings", null);
    },
  },
};
