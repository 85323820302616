import { getBusinessHours } from "../../apis/merchant";
export default {
    namespaced: true,
    state: () => ({
        businessHours: {}
    }),
    getters: {
        getBusinessHours: (state) => {
            return state.businessHours
        }
    },
    mutations: {
        setBusinessHours(state, businessHours) {
            state.businessHours = businessHours;
        }
    },
    actions: {
        async initBusinessHours({ commit }, token) {
            const data = await getBusinessHours(token);
            commit('setBusinessHours', data);
            return data;
        },
        setBusinessHours({ commit }, businessHours) {
            commit("setBusinessHours", businessHours)
        },
        reset({ commit }) {
            commit("setBusinessHours", null)
        }
    }
}