import axios from "axios";
import { putItemInList } from "../../../functions/utils";

export default {
	namespaced: true,
	state: () => ({
		orders: [],
	}),
	getters: {
		getOrders: (state) => state.orders,
		getOrder: (state) => (orderId) => state.orders.find((order) => order.id == orderId),
	},
	mutations: {
		setOrders: (state, orders) => (state.orders = [...orders]),
		addOrder: (state, order) => putItemInList(state.orders, order, "id"),
		reset: (state) => (state.orders = []),
	},
	actions: {
		getUniqueOrderStores() {
			return axios.get("/user/orders/stores");
		},
		getOrdersCount(_, filter) {
			return axios.get("/user/orders/count", { params: filter });
		},
		async getOrders({ commit }, params) {
			const data = await axios.get("/user/orders", { params: params });
			commit("setOrders", data);
			return data;
		},
		async getOrder({ commit, getters }, orderId) {
			const order = getters.getOrder(orderId);
			if (order) return Promise.resolve(order);

			const data = await axios.get("/user/orders/" + orderId);
			commit("addOrder", data);
			return data;
		},
		getOrderItems(_, orderId) {
			return axios.get("/user/orders/" + orderId + "/items");
		},
		getOrderPrescriptions(_, orderId) {
			return axios.get("/user/orders/" + orderId + "/prescriptions");
		},
		reorder(_, orderId) {
			return axios.post("user/orders/" + orderId + "/reorder");
		},
		reset: ({ commit }) => commit("reset"),
	},
};
