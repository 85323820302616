import store from "../store";

// delivery settings
import deliverySettings from "../store-delivery-settings";

// business hours
import businessHours from "../store-business-hours";

// orders
import merchantOrdersStore from "./merchant-orders-store";

import genericListStore from "../../../functions/genericListStore";

export default {
  namespaced: true,
  modules: {
    store,
    deliverySettings,
    businessHours,
    categories: genericListStore,
    products: genericListStore,
    orders: merchantOrdersStore,
  },
};
