import axios from "axios";

const GET = (url, headers, params, responseType) => {
  return axios.get(url, {
    headers,
    params,
    responseType: responseType,
  });
};

const POST = (url, headers, params, data) => {
  return axios.post(url, data, {
    headers,
    params,
  });
};

const PUT = (url, headers, params, data) => {
  return axios.put(url, data, {
    headers,
    params,
  });
};

const DELETE = (url, headers) => {
  return axios.delete(url, {
    headers,
  });
};

export default {
  GET,
  POST,
  PUT,
  DELETE,
};
