import axios from "axios";
import { putItemInList, removeItemFromList } from "../../../functions/utils";

export default {
	namespaced: true,
	state: () => ({
		prescriptions: [],
	}),
	getters: {
		getPrescriptions: (state) => state.prescriptions,
	},
	mutations: {
		setPrescriptions: (state, prescriptions) => (state.prescriptions = [...prescriptions]),
		addPrescription: (state, prescription) => putItemInList(state.prescriptions, prescription, "id"),
		removePrescription: (state, prescriptionId) => removeItemFromList(state.prescriptions, { id: prescriptionId }, "id"),
		reset: (state) => (state.prescriptions = []),
	},
	actions: {
		async getPrescriptions({ commit }) {
			const data = await axios.get("/user/prescriptions");
			commit("setPrescriptions", data);
			return data;
		},
		async addPrescription({ commit }, form) {
			const data = await axios.post("/user/prescriptions", form);
			commit("addPrescription", data);
			return data;
		},
		async deletePrescription({ commit }, prescriptionId) {
			const data = await axios.delete("/user/prescriptions/" + prescriptionId);
			commit("removePrescription", prescriptionId);
			return data;
		},
		reset: ({ commit }) => commit("reset"),
	},
};
