// FORM
export const toFormData = (json) => {
  var formDataObj = new FormData();

  // converting json into formData/
  for (var key in json) {
    const value = json[key];
    if (value) {
      formDataObj.append(key, value);
    }
  }

  return formDataObj;
};

// LIST
export const putItemInList = (list, item, keyToCompare) => {
  // finding element
  let index = list.findIndex(
    (element) => element[keyToCompare] == item[keyToCompare]
  );
  if (index !== -1) {
    // item found, overwrite previous item
    list.splice(index, 1, item);
  } else {
    // add new item to new array
    list.push(item);
  }
  // returning same list back.
  return list;
};

export const removeItemFromList = (list, item, keyToCompare) => {
  // finding element
  let index = list.findIndex(
    (element) => element[keyToCompare] == item[keyToCompare]
  );
  if (index !== -1) {
    // item found, removing item
    list.splice(index, 1);
  }
  // returning same list back.
  return list;
};

// FILE
const BUFFER_ARRAY = "bufferArray";
const DATA_URL = "dataURL";

export const fileToURL = (file) => {
  return fileToObject(file, DATA_URL);
};

export const fileToBufferArray = (file) => {
  return fileToObject(file, BUFFER_ARRAY);
};

export const fileToObject = (file, readerType) => {
  return new Promise((res, rej) => {
    // file empty
    if (!file) {
      res(null);
      return;
    }

    const reader = new FileReader();
    reader.onerror = (e) => rej(e);
    if (readerType == BUFFER_ARRAY) {
      reader.readAsArrayBuffer(file);
    } else if (readerType == DATA_URL) {
      reader.readAsDataURL(file);
    } else {
      reader.readAsDataURL(file);
    }
    reader.onload = (e) => {
      const result = e.target.result;
      res(result);
    };
  });
};

export const arrayToFile = (array, fileName, type, lastModifiedDate) => {
  return new File([array], fileName, { type: lastModifiedDate });
};

// ARRAY
// SUM OF NUMBERS
export const sumOfNumbersArray = (array) => {
  return array.reduce(function(a, b) {
    return a + b;
  }, 0);
};

// TIME
export const convertTimeFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? "AM" : "PM";
  const hours = +sHours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
};

export function calcTimeDiff(greaterDate, smallerDate) {
  let diffInMilliSeconds = Math.abs(greaterDate - smallerDate);
  return breakdownMilliseconds(diffInMilliSeconds);
}

export function breakdownMilliseconds(milliSeconds) {
  let millisInFraction = milliSeconds / 1000;

  // calculate days
  const days = Math.floor(millisInFraction / 86400);
  millisInFraction -= days * 86400;

  // calculate hours
  const hours = Math.floor(millisInFraction / 3600) % 24;
  millisInFraction -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(millisInFraction / 60) % 60;
  millisInFraction -= minutes * 60;

  return { days, hours, minutes };
}

export function toMilliseconds(date, time) {
  if (!date || !(date instanceof Date)) return null;
  let milliSeconds = date.getTime();
  if (time) {
    const split = String(time).split(":");
    const hrs = split.length > 0 ? split[0] : 0;
    const mins = split.length > 1 ? split[1] : 0;
    const secs = split.length > 2 ? split[2] : 0;
    // adding time to milliseconds
    milliSeconds += hrs * 60 * 60 * 1000;
    milliSeconds += mins * 60 * 1000;
    milliSeconds += secs * 1000;
  }
  return milliSeconds;
}

// Delay
export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
