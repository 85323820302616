import { variables } from "@/debug";

export default {
	namespaced: true,
	state: {
		token: JSON.parse(localStorage.getItem("login-token")) || variables.loginToken,
	},
	getters: {
		getToken: (state) => state.token,
	},
	mutations: {
		saveToken(state, token) {
			// saving token in storage
			if (token) localStorage.setItem("login-token", JSON.stringify(token));
			else localStorage.removeItem("login-token");
			state.token = token;
		},
	},
	actions: {
		saveToken({ commit, dispatch }, payload) {
			commit("saveToken", payload);
			return dispatch("guest/carts/addGuestCartItemsToUserCart", null, { root: true });
		},
		logout({ commit, dispatch }) {
			commit("saveToken", null);

			// masters
			dispatch("masters/reset", null, { root: true });

			// profile
			dispatch("profile/reset", null, { root: true });

			// product images
			dispatch("productImages/reset", null, { root: true });

			// merchant
			dispatch("merchant/store/reset", null, { root: true });
			dispatch("merchant/categories/reset", null, { root: true });
			dispatch("merchant/products/reset", null, { root: true });
			dispatch("merchant/orders/reset", null, { root: true });

			// guest
			// dispatch("guest/store/reset", null, { root: true })
			dispatch("guest/categories/reset", null, { root: true });
			dispatch("guest/products/reset", null, { root: true });
			dispatch("guest/carts/removeGuestCarts", null, { root: true });

			// user
			// carts
			dispatch("user/cart/removeCarts", null, { root: true });
			// orders
			dispatch("user/orders/reset", null, { root: true });
			// prescriptions
			dispatch("user/userPrescriptions/reset", null, { root: true });
			dispatch("user/userCartPrescriptions/reset", null, { root: true });
		},
	},
};
