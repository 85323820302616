import store from "@/store";

const performPostLoginGuard = (_to, _from, next) => {
  const isUserAuthenticated = store.getters["login/getToken"] ? true : false;
  // redirect to home page
  if (!isUserAuthenticated) {
    console.log("Redirecting to Pre Login");
    next("/");
  } else {
    next();
  }
};

const performPreLoginGuard = (_to, _from, next) => {
  const isUserAuthenticated = store.getters["login/getToken"] ? true : false;
  // redirect to post login page
  if (isUserAuthenticated) {
    console.log("Redirecting to Post Login");
    next("/");
  } else {
    next();
  }
};

// COMMON GUARD
export const addGlobalRouterGuard = (router) => {
  router.beforeEach((to, from, next) => {
    const isPostLoginPath = to.path.startsWith("/postLogin");
    if (isPostLoginPath) {
      performPostLoginGuard(to, from, next);
      return;
    }

    const isPreLoginPath = to.path.startsWith("/preLogin");
    if (isPreLoginPath) {
      performPreLoginGuard(to, from, next);
      return;
    }

    next();
  });
};
