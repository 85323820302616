import Vue from "vue";
import Vuex from "vuex";

// login
import authStore from "./modules/auth-store";

// masters
import masters from "./modules/masters";

// profile
import profile from "./modules/profile-store";

// product images
import productImagesStore from "./modules/product-images-store";

// admin
import adminStore from "./modules/admin";

// merchant store
import merchantStore from "./modules/merchant-store";

// guest store
import guestStore from "./modules/guest-store";

// user store
import userStore from "./modules/user-store";

// app state
import appState from "./modules/appState";

Vue.use(Vuex);
export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		login: authStore,
		masters,
		profile,
		productImages: productImagesStore,
		merchant: merchantStore,
		guest: guestStore,
		user: userStore,
		admin: adminStore,
		appState,
	},
});
