import prescriptionRoutes from "./user-prescription-routes";

export default {
	path: "user-account",
	name: "UserAccount",
	component: () => import("@/components/common/BlankParent"),
	redirect: "user-account/page",
	children: [
		// Account Page
		{
			path: "page",
			component: () => import("@/views/buyer/account/UserAccountPage"),
		},

		// Addresses
		{
			path: "addresses",
			name: "UserAddresses",
			component: () => import("@/views/buyer/account/addresses/UserAddresses"),
		},
		{
			path: "address-form",
			name: "UserAddressForm",
			component: () => import("@/views/buyer/account/addresses/UserAddressForm"),
		},

		// Prescriptions
		...prescriptionRoutes,
	],
};
