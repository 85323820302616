import axios from "axios";
import { putItemInList, removeItemFromList } from "../../../functions/utils";

export default {
	namespaced: true,
	state: () => ({
		list: [],
	}),
	getters: {
		getList: (state) => state.list,
		getItem: (state) => (id) => state.list.find((item) => item.id == id),
	},
	mutations: {
		setList: (state, list) => (state.list = list),
		addItem: (state, item) => putItemInList(state.list, item, "id"),
		removeItem: (state, item) => removeItemFromList(state.list, item, "id"),
		reset: (state) => (state.list = []),
	},
	actions: {
		setList: ({ commit }, payload) => commit("setList", payload),
		addItem: ({ commit }, payload) => commit("addItem", payload),
		removeItem: ({ commit }, payload) => commit("removeItem", payload),
		reset: ({ commit }) => commit("reset"),

		async getProductAndQuantity(_, params) {
			const list = await axios.get("/merchant/orders/products", { params });
			return [...list].map(({ product, quantity }) => {
				return {
					id: product.id,
					unit: product.unit,
					name: product.name,
					quantity: quantity,
				};
			});
		},
		getOrderPrescriptions(_, orderId) {
			return axios.get("/merchant/orders/" + orderId + "/prescriptions");
		},
	},
};
