import axios from "axios";
import { putItemInList } from "../../../functions/utils";

import userOrdersStore from "./user-orders-store";
import userPrescriptions from "./user-prescriptions";

import cart from "../cart";
import cartItems from "../cart/cartItems";

import userCartPrescriptions from "./user-cart-prescriptions";

export default {
	namespaced: true,
	state: () => ({
		userStoreHistory: [],
	}),
	getters: {
		getUserStoreHistory(state) {
			return state.userStoreHistory;
		},
	},
	mutations: {
		setHistory(state, history) {
			state.userStoreHistory = [...history];
		},
		addItemToHistory(state, item) {
			putItemInList(state.userStoreHistory, item, "id");
		},
	},
	actions: {
		async initHistory({ commit }) {
			const data = await axios.get("/user/store/history");
			commit("setHistory", data);
			return data;
		},
		storeVisited({ commit, rootGetters }) {
			const token = rootGetters["login/getToken"];
			const store = rootGetters["guest/store/getStore"];
			if (token && store) {
				axios.put("/user/store/history/" + store.id).then((data) => commit("addItemToHistory", data));
			}
		},
	},
	modules: {
		orders: userOrdersStore,
		cart,
		cartItems,
		userPrescriptions,
		userCartPrescriptions,
	},
};
