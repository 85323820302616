import genericListStore from "../../../functions/genericListStore";
import axios from "axios";

const cartItemsStore = {
	...genericListStore,
};

cartItemsStore.getters = {
	...cartItemsStore.getters,

	getCartItemOfProduct: (state) => {
		return (product) => {
			const cartItem = state.list.find((item) => {
				if (item.product.id === product.id) {
					return true;
				}
				return false;
			});
			return cartItem;
		};
	},
};

cartItemsStore.actions = {
	...cartItemsStore.actions,
	// Adding cartItems to cart without saving to store
	async addGuestCartItems(_, { storeUrlName, item }) {
		const url = `/user/stores/${storeUrlName}/cart/items`;
		const productId = item.productId;
		const quantity = item.quantity;
		const body = { productId, quantity };
		const data = await axios.post(url, body);
		return data;
	},
	async getUserCartItems({ commit, rootGetters }) {
		const { storeUrlName } = rootGetters["guest/store/getStore"];
		const url = `/user/stores/${storeUrlName}/cart/items`;
		const data = await axios.get(url);
		commit("setList", data);
		return data;
	},
	async updateUserCartItems(_, { storeUrlName, cartItemId, quantity }) {
		const url = `/user/stores/${storeUrlName}/cart/items/${cartItemId}`;
		const body = { quantity };
		return axios.put(url, body);
	},
};

export default cartItemsStore;
