import httpClient from "../functions/httpClient";
// -------------------------------------- STORE --------------------------------------
export const getStore = (storeUrlName) => {
  return httpClient.GET("/stores/" + storeUrlName);
};
export const getStoresTodaysBusinessHours = (storeUrlName) => {
  return httpClient.GET("/stores/" + storeUrlName + "/business-hours/today");
};
// --------------------------------------END STORE --------------------------------------

// -------------------------------------- CATEGORIES --------------------------------------
export const getCategories = (storeUrlName) => {
  return httpClient.GET("/stores/" + storeUrlName + "/categories");
};
// -------------------------------------- END CATEGORIES --------------------------------------

// -------------------------------------- PRODUCTS --------------------------------------
export const getProductsCount = (storeUrlName, params) => {
  return httpClient.GET(
    "/stores/" + storeUrlName + "/products/count",
    null,
    params
  );
};
export const getProducts = (storeUrlName, params) => {
  return httpClient.GET("/stores/" + storeUrlName + "/products", null, params);
};
export const getProduct = (storeUrlName, productId) => {
  return httpClient.GET("/stores/" + storeUrlName + "/products/" + productId);
};

// -------------------------------------- PRODUCT IMAGES --------------------------------------
export const getProductImages = (storeUrlName, productId) => {
  return httpClient.GET(
    "/stores/" + storeUrlName + "/products/" + productId + "/images"
  );
};
// -------------------------------------- END PRODUCT IMAGES --------------------------------------
// -------------------------------------- END PRODUCTS --------------------------------------
