import { getStore } from "../../../apis/guest";

export default {
	namespaced: true,
	state: () => ({
		store: null,
	}),
	getters: {
		getStore: (state) => state.store,
		getStoreUrlName: (state) => (state.store ? state.store.storeUrlName : null),
	},
	mutations: {
		setStore(state, store) {
			state.store = store;
		},
	},
	actions: {
		async init({ commit }, storeUrlName) {
			const data = await getStore(storeUrlName);
			commit("setStore", data);
			return data;
		},
	},
};
