import cartRoutes from "./cart-routes";

const routes = [
  // Dashboard
  {
    path: "s-dashboard",
    name: "StoreDashboard",
    component: () => import("../../views/store/home/Dashboard"),
  },

  // Products
  // {
  //     path: "products",
  //     name: "StoreProducts",
  //     component: () => import('../../views/store/products/ProductsParent'),
  //     redirect: "products/list",
  //     children: [
  //         // Products
  //         {
  //             path: "list",
  //             component: () => import('../../views/store/products/ProductsList'),
  //         },
  //     ]
  // },

  // Cart
  cartRoutes,

  // Orders
  {
    path: "orders",
    component: () => import("../../views/store/orders/OrdersParent"),
    name: "StoreOrders",
    redirect: "orders/list",
    children: [
      {
        path: "list",
        component: () => import("../../views/store/orders/OrdersList"),
      },
      {
        path: "detail",
        name: "StoreOrderDetail",
        component: () => import("../../views/store/orders/OrderDetail"),
      },
    ],
  },
];

export default routes;
