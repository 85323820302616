export default {
    namespaced: true,
    state: {
        profile: null
    },
    getters: {
        getProfile: (state) => state.profile
    },
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        }
    },
    actions: {
        setProfile({ commit }, payload) {
            commit("setProfile", payload)
        },
        reset({commit}){
            commit("setProfile", null)
        }
    }
}