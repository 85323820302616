export default {
  path: "user-orders",
  name: "UserOrders",
  component: () => import("@/components/common/BlankParent"),
  redirect: "user-orders/list",
  children: [
    {
      path: "list",
      component: () => import("@/views/buyer/orders/OrdersList"),
    },
    {
      path: "detail",
      name: "UserOrderDetail",
      component: () => import("@/views/buyer/orders/OrderDetail"),
    },
  ],
};
