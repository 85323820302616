import genericListStore from "../../../functions/genericListStore";

import carts from "../guest-carts";
// import cart from "../cart";
// import cartItems from "../cart/cartItems";

// import store from "../store";
import visitedStore from "./current-store";

export default {
  namespaced: true,
  modules: {
    store: visitedStore,
    categories: genericListStore,
    products: genericListStore,
    carts: carts,
    // cartItems: cartItems,
  },
};
